 // @Update-check
.condensed {
  mat-form-field {
    height: 2.5rem;
    font-size: .75rem !important;

    mat-label {
      font-size: .75rem;
    }

    .mat-mdc-text-field-wrapper {
      height: 2.5rem;

      .mat-mdc-form-field-infix {
        min-height: unset;
        height: 2.5rem;
        padding-top: .5rem;
        padding-bottom: .5rem;
      }

      .mat-mdc-floating-label {
        top: 1.15rem;
      }

      .mdc-floating-label--float-above {
        transform: translateY(-1.625rem) scale(.75) !important;
      }
    }

    .mat-mdc-form-field-icon-prefix,
    .mat-mdc-form-field-icon-prefix button,
    .mat-mdc-form-field-icon-suffix,
    .mat-mdc-form-field-icon-suffix button {
      width: 2.5rem;
      height: 2.5rem;

      mat-icon,
      .mat-datepicker-toggle-default-icon {
        width: 1.25rem;
        height: 1.25rem;
        font-size: 1.25rem;
      }

      mat-icon {
        padding: .625rem;
      }

      .mat-datepicker-toggle-default-icon {
        position: relative;
        top: -3px;
        left: -3px;
      }
    }
  }
}

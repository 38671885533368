/* You can add global styles to this file, and also import other style files */

$primary-color: #014a90;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

mat-nav-list a.active {
  background: #f4f4f4;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.row-reverse {
  flex-direction: row-reverse;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.bold {
  font-weight: 500;
}

.font-size-3\/4 {
  font-size: .75rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-error {
  color: #f44336;
}

.opacity-50 {
  opacity: .5;
}

.pointer {
  cursor: pointer;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-8 {
  margin-right: 2rem !important;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-4 {
  margin-left: 1rem;
}

.rotate-180 {
  transform: rotateX(180deg);
}

.w-1\/5 {
  width: 20%;
}

.w-100 {
  width: 100%;
}

.mat-badge { // @Update check
  &.badge-hide-text {
    .mat-badge-content {
      color: transparent;
    }
  }

  &.badge-infix {
    .mat-badge-content {
      top: 0px !important;
      right: 0px !important;
    }
  }
}

.dialog-close-button {
  position: absolute !important;
  top: 0;
  right: 0;
  z-index: 1 !important;
}

.autocomplete-table .mdc-list-item__primary-text {
  display: block;
  width: 100%;
}

.mat-calendar-body-selected {
  color: #fff !important;
}

.button-like {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $primary-color;
  text-decoration: none;

  mat-icon + span {
    margin-left: .5rem
  }

  span + mat-icon {
    margin-left: .5rem
  }
}

metrans-header,
.headline,
.headline ~ .progress-bar-wrap {
  position: sticky;
  top: 0;
  z-index: 2;
}

.headline {
  /*
   * Header height
   */
  top: 64px; // @Update check

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  background: #e3eaf0;
  color: $primary-color;
  padding-left: 1rem;

  h1,
  h2 {
    font-size: .875rem;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }
}

.headline ~ .progress-bar-wrap {
  /*
   * Header height 64px
   * .headline height 3rem
   */
  top: calc(64px + 3rem); // @Update check
}

.progress-bar-wrap {
  width: 100%;
  height: 4px;
  margin: 0 0 1rem;
}


.multiline-tooltip  { // @Update-check
  white-space: pre-line;

  .mdc-tooltip__surface {
    text-align: left;
  }
}

.filter-calendar,
.filter-calendar + .mat-datepicker-actions { // @Update-check
  border: 2px solid $primary-color;
}

.filter-calendar { // @Update-check
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom: none;
}

.filter-calendar + .mat-datepicker-actions { // @Update-check
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top: none;
}
